import { createContext, useCallback, useState, useMemo, useEffect } from 'react'
import { ILayoutThemeProvider, TLayoutThemeContext } from './types'
import getMuiTheme from '../../utils/themeUtils'
import { DARK_THEME, LIGHT_THEME } from '../../utils/themeConstants'

export const LayoutThemeContext = createContext<TLayoutThemeContext | null>(null)

const LayoutThemeProvider: React.FC<ILayoutThemeProvider> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const [lightTheme, setLightTheme] = useState('')
  const appMuiTheme = useMemo(
    () => (lightTheme === 'light' ? getMuiTheme(LIGHT_THEME) : getMuiTheme(DARK_THEME)),
    [lightTheme]
  )

  const changeTheme = useCallback(() => {
    if (lightTheme === 'dark') {
      setLightTheme('light')
      localStorage.setItem('theme', 'light')
      return
    }
    setLightTheme('dark')
    localStorage.setItem('theme', 'dark')
  }, [lightTheme, setLightTheme])

  useEffect(() => {
    const theme = localStorage.getItem('theme')
    if (theme) {
      setLightTheme(theme)
    } else {
      setLightTheme('light')
    }
  }, [])

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen)
  }, [mobileOpen])

  const contextValue = useMemo(
    () => ({
      handleDrawerToggle,
      mobileOpen,
      changeTheme,
      appMuiTheme,
    }),
    [handleDrawerToggle, mobileOpen, changeTheme, appMuiTheme]
  )

  return <LayoutThemeContext.Provider value={contextValue}>{children}</LayoutThemeContext.Provider>
}

export default LayoutThemeProvider
