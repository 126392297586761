import { PaletteColorOptions, ThemeOptions } from '@mui/material'
import { createTheme as createMuiTheme, Theme } from '@mui/material/styles'
import { components, typography } from './themeConstants'

const getMuiTheme = (theme: ThemeOptions | undefined): Theme =>
  createMuiTheme({
    ...typography,
    ...components,
    ...theme,
  })

declare module '@mui/material/styles' {
  interface CustomPalette {
    adminButton: PaletteColorOptions
    userButton: PaletteColorOptions
    purpleTheme: PaletteColorOptions
  }
  type Palette = CustomPalette
  type PaletteOptions = CustomPalette
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    adminButton: true
    userButton: true
    purpleTheme: true
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    fileActions: true
  }
}
declare module '@mui/material/styles' {
  interface TypographyVariants {
    buttonTitle: React.CSSProperties
    listItemText: React.CSSProperties
    label: React.CSSProperties
    body2Light: React.CSSProperties
    font13400: React.CSSProperties
    font13500: React.CSSProperties
    font13600: React.CSSProperties
    font14500: React.CSSProperties
    font17500: React.CSSProperties
    font17600: React.CSSProperties
    font30600: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    buttonTitle?: React.CSSProperties
    listItemText?: React.CSSProperties
    label: React.CSSProperties
    body2Light: React.CSSProperties
    font13400: React.CSSProperties
    font13500: React.CSSProperties
    font13600: React.CSSProperties
    font14500: React.CSSProperties
    font17500: React.CSSProperties
    font17600: React.CSSProperties
    font30600: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    buttonTitle: true
    listItemText: true
    label: true
    body2Light: true
    font13400: true
    font13500: true
    font13600: true
    font14500: true
    font17500: true
    font17600: true
    font30600: true
  }
}

export default getMuiTheme
