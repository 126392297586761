import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'

const LoginAuth: React.FC = () => {
  const isAuth = useAuth()
  const location = useLocation()
  if (isAuth) return <Navigate to="/" state={{ from: location }} replace />

  return <Outlet />
}

export default LoginAuth
