import { Box, Button, FormControl, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { IVerifyPage } from './types'
import TextInput from '../../atoms/TextInput'
import PoweredByBinarii from '../../atoms/PoweredByBinarii'

const VerifyPage: React.FC<IVerifyPage> = ({ resendCode, onVerifyFormSubmit }) => {
  const [code, setCode] = useState<string>('')

  const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value)
  }
  // eslint-disable-next-line
  const onVerifyCodeFormSubmit = async (e: React.SyntheticEvent) => {
    if (code) {
      onVerifyFormSubmit(code)
    }
  }

  return (
    <Grid sx={{ height: '85vh', bgcolor: 'background.paper' }}>
      <Grid height="100%" container justifyContent="center" alignItems="center">
        <Grid
          item
          className="login-inner-container"
          sx={{ '&': { display: 'flex', flexDirection: 'column', alignItems: 'center' } }}
        >
          <Box className="login-box">
            <Typography
              color="inherit"
              align="center"
              sx={{ mb: 1, fontSize: '2.1rem', lineHeight: '2.4rem', fontWeight: 600 }}
            >
              Verify Account
            </Typography>
            <Typography color="inherit" align="center" sx={{ fontSize: '1.4rem' }}>
              Please enter the verification code
              <br />
              we sent to your email
            </Typography>

            <FormControl sx={{ width: '100%' }}>
              <TextInput
                variant="outlined"
                value={code}
                onChange={onCodeChange}
                label="Code"
                type="text"
                id="code-id"
                aria-describedby="code-helper-text"
                fullWidth
                autoComplete="code"
              />
            </FormControl>
            <Button
              size="large"
              variant="contained"
              color="purpleTheme"
              sx={{
                mt: '1.6rem',
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '.5rem',
              }}
              type="submit"
              onClick={onVerifyCodeFormSubmit}
              disabled={!(code.length >= 3)}
            >
              Submit
            </Button>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => {
                  resendCode()
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '1.5rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '2.4rem',
                    textTransform: 'capitalize',
                    color: '#546473',
                    width: 'fit-content',
                  }}
                >
                  Resend Code
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid sx={{ height: '15vh', bgcolor: 'background.paper' }}>
        <PoweredByBinarii />
      </Grid>
    </Grid>
  )
}

export default VerifyPage
