import { Box, Typography } from '@mui/material'
import binariiLogo from '../../../assets/images/binariilabs.svg'
import binariiLogoWhite from '../../../assets/images/binariilabsWhite.svg'

interface IPoweredByBinarii {
  light?: 'dark' | 'white'
}

const PoweredByBinarii: React.FC<IPoweredByBinarii> = ({ light = 'dark' }) => (
  <Box
    sx={{
      display: 'flex',
      padding: '1.6rem',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '5.4rem',
      backgroundColor: 'transparent',
    }}
  >
    <Typography variant="body2" sx={{ color: '#7A8897' }}>
      Powered by
    </Typography>
    <img
      src={light === 'dark' ? binariiLogo : binariiLogoWhite}
      style={{ width: '7.2rem', height: '2.5rem' }}
      alt="binarii logo"
    />
  </Box>
)

export default PoweredByBinarii
