import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'

const LoggedInPage = lazy(() => import('../../pages/LoggedInPage'))

const RequireAuth: React.FC = () => {
  const isAuth = useAuth()

  if (isAuth) {
    return <LoggedInPage />
  }
  return <Navigate to="/login" replace />
}

export default RequireAuth
