/* eslint-disable */
import axios from 'axios'
import config from '../config'

export const binariiApi = axios.create({
  baseURL: config.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})
