import { Box, Button, FormControl, FormHelperText, Grid, Typography } from '@mui/material'
import '../LoginPage/LoginPage.scss'
import React, { useState } from 'react'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import userService from '../../../services/userService'
import IErrors from '../../../types/IErrors'
import validators from '../../../utils/validators'
import { IForgotPassword } from './types'
import TextInput from '../../atoms/TextInput'
import PoweredByBinarii from '../../atoms/PoweredByBinarii'

/**
 * React component for the "Forgot Password" page.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {Function} props.navigateToLogin - Function to navigate to the login page.
 */
const ForgotPassword: React.FC<IForgotPassword> = ({ navigateToLogin }) => {
  // State variables to manage form inputs and errors
  const [showError, setShowError] = useState<boolean | null>(null)
  const [email, setEmail] = useState<string>('')
  const [emailError, setemailError] = useState<string>('')

  // Snackbar utility for displaying messages
  const { enqueueSnackbar } = useSnackbar()

  /**
   * Event handler for email input changes.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
   */
  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  /**
   * Event handler for form submission.
   *
   * @param {React.SyntheticEvent} e - The form submission event.
   */
  const onLoginFormSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault()
    setemailError('')
    setShowError(null)
    let hasErrors

    // Validate the email input
    if (!email || !validators.validateEmail(email)) {
      setShowError(true)
      setemailError('Email is not valid')
      hasErrors = true
    }

    // If there are validation errors, stop the submission
    if (hasErrors) return

    try {
      // Send a request to reset the password
      await userService.forgotPassword(email)

      // Display a success message in the snackbar
      enqueueSnackbar(`Password reset instructions have been sent to the email ${email}`, {
        variant: 'info',
      })
    } catch (err) {
      const error = err as AxiosError<IErrors>

      // Extract the error message from the response data, if available
      const message = error.response?.data.errors[0].message

      // Display the error message in the snackbar
      if (message) {
        enqueueSnackbar(message, { variant: 'error' })
      }
    }
  }

  const isButtonDisabled = () => !validators.validateEmail(email)

  return (
    <Grid sx={{ height: '85vh', bgcolor: 'background.paper' }}>
      <Grid height="100%" container justifyContent="center" alignItems="center">
        <Grid
          item
          className="login-inner-container"
          sx={{ '&': { display: 'flex', flexDirection: 'column', alignItems: 'center' } }}
        >
          <Box className="login-box">
            <Typography
              color="inherit"
              align="center"
              sx={{ mb: 1, fontSize: '2.1rem', lineHeight: '2.4rem', fontWeight: 600 }}
            >
              Reset Password
            </Typography>
            <Typography color="inherit" align="center" sx={{ fontSize: '1.4rem' }}>
              Forgot Your Password? No worries!
              <br /> We will send you a new password to your email
            </Typography>
            <form className="login-form" onSubmit={onLoginFormSubmit}>
              <FormControl>
                <TextInput
                  variant="outlined"
                  value={email}
                  onChange={onEmailChange}
                  label="Email"
                  type="text"
                  id="email-input"
                  aria-describedby="email-helper-text"
                  fullWidth
                  autoComplete="email"
                />
                {showError && (
                  <FormHelperText id="email-helper-text" error sx={{ fontSize: 12 }}>
                    {emailError}
                  </FormHelperText>
                )}
              </FormControl>

              <Button
                size="large"
                variant="contained"
                color="purpleTheme"
                sx={{
                  mt: '1.6rem',
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: '.5rem',
                }}
                type="submit"
                disabled={isButtonDisabled()}
              >
                Reset Password
              </Button>
            </form>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => {
                  navigateToLogin()
                }}
                startIcon={<ChevronLeftIcon sx={{ color: '#546473', marginRight: 0 }} />}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '1.5rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '2.4rem',
                    textTransform: 'capitalize',
                    color: '#546473',
                  }}
                >
                  Back to login
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid sx={{ height: '15vh', bgcolor: 'background.paper' }}>
        <PoweredByBinarii />
      </Grid>
    </Grid>
  )
}

export default ForgotPassword
