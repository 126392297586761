import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { binariiApi } from '../../../../utils/axiosConfig'
import LoginPage from '../../../pages/LoginPage'
import VerifyPage from '../../../pages/VerifyPage'
import { EUserRole, userJWTData } from '../../../../redux/user/userTypes'
import ForgotPassword from '../../../pages/ForgotPassword'

/**
 * A wizard component for guiding users through the Multi-Factor Authentication (MFA) setup process.
 * Manages the steps of login and verification for MFA.
 *
 * @component
 * @returns {JSX.Element} - The rendered MultiFactorAuthWizzard component.
 */
const MultiFactorAuthWizzard: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [jwtToken, setJwtToken] = useState<string>('')
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const loginRedirect = (navigateTo?: string) => {
    if (navigateTo) {
      navigate(navigateTo)
      return
    }
    navigate(0)
  }
  // Configuration for authorization headers
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` },
  }

  /**
   * Sends a verification code to the user for Multi-Factor Authentication.
   *
   * @function
   * @param {string} [token] - Optional token for authorization headers.
   * @returns {void}
   */
  const sendCodeToUser = (token?: string) => {
    binariiApi.post(
      'code/user/send',
      {},
      {
        headers: { Authorization: `Bearer ${token || jwtToken}` },
      }
    )
  }

  /**
   * Handles the submission of the login form, initiates MFA setup if needed.
   *
   * @async
   * @function
   * @param {string} username - The user's username.
   * @param {string} password - The user's password.
   * @returns {Promise<void>} - A Promise that resolves when the login and MFA setup are complete.
   */
  const onLoginFormSubmit = async (username: string, password: string): Promise<void> => {
    try {
      // Attempt to perform a login request to the backend API
      const loginResponse = await binariiApi.post('login', { username, password })

      // Check if the login request was successful and a token was received
      if (loginResponse?.data?.token) {
        // Set the JWT token in the application
        setJwtToken(loginResponse?.data?.token)

        // Decode the JWT token to extract multifactor indicator and user roles
        const { mfa: multifactorIndicator, roles } = jwtDecode(
          loginResponse?.data?.token
        ) as userJWTData

        // Check if multifactor authentication is not required
        if (!multifactorIndicator) {
          // Store the token in local storage for future use
          localStorage.setItem('token', loginResponse?.data?.token)

          // Redirect the user based on their role
          if (roles.includes(EUserRole.ROLE_SUPER_ADMIN)) {
            loginRedirect('/super-admin/dashboard')
            return
          }
          loginRedirect()
          return
        }

        // If multifactor authentication is required, send a verification code to the user
        sendCodeToUser(loginResponse?.data?.token)
        setCurrentStep(2)
      }
    } catch (err: any) {
      // Handle any errors that occur during the login process
      const error = err as AxiosError<{ error: string }>

      // Extract the error message from the API response, if available
      const message = error.response?.data.error

      // Display the error message to the user (consider improving the error handling mechanism)
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  /**
   * Handles the submission of the verification form.
   *
   * @async
   * @function
   * @param {string} enteredCode - The verification code entered by the user.
   * @returns {Promise<void>} - A Promise that resolves when the verification is complete.
   */
  const onVerifyPageSubmit = async (enteredCode: string): Promise<void> => {
    try {
      await binariiApi.post('code/user/verify', { enteredCode }, config)
      localStorage.setItem('token', jwtToken)
      loginRedirect()
    } catch (err: any) {
      // eslint-disable-next-line
      console.log('Error:', err) // TODO: Consider handling the error more effectively.
    }
  }
  /**
   * Function to navigate to the "Forgot Password" step.
   * Sets the current step to 3, indicating the transition to the "Forgot Password" step.
   */
  const navigateToForgotPassword = () => {
    setCurrentStep(3)
  }

  /**
   * Function to navigate to the "Login" step.
   * Sets the current step to 1, indicating the transition to the "Login" step.
   */
  const navigateToLogin = () => {
    setCurrentStep(1)
  }
  // Define the wizard steps and their corresponding pages.
  const steps = [
    {
      step: 1,
      page: (
        <LoginPage
          loginSubmitFunction={onLoginFormSubmit}
          navigateToForgotPassword={navigateToForgotPassword}
        />
      ),
    },
    {
      step: 2,
      page: <VerifyPage resendCode={sendCodeToUser} onVerifyFormSubmit={onVerifyPageSubmit} />,
    },
    {
      step: 3,
      page: <ForgotPassword navigateToLogin={navigateToLogin} />,
    },
  ]

  // Determine the current step to render.
  const renderedStep = useMemo(() => {
    const findComponentByStep = steps.find(step => step.step === currentStep)
    if (findComponentByStep) return findComponentByStep.page
    return <>Invalid step</> // TODO: Consider displaying an error page instead.
  }, [currentStep])

  return renderedStep
}

export default MultiFactorAuthWizzard
