import { Stack, TextField, Typography } from '@mui/material'
import { CustomTextFieldProps } from './types'

const TextInput: React.FC<CustomTextFieldProps> = ({
  label,
  InputProps,
  error = false,
  errorMessage = '',
  ...props
}: CustomTextFieldProps) => (
  <Stack sx={{ gap: '.6rem', width: '100%' }}>
    <Typography variant="label" sx={{ color: '#6C6C6C' }}>
      {label}
    </Typography>
    <TextField
      {...props}
      variant="standard"
      InputProps={{
        disableUnderline: true,
        style: {
          fontSize: '1.6rem',
          fontWeight: 400,
          lineHeight: '2.4rem',
          padding: '0 !important',
          color: '#444444',
        },
        inputProps: {
          maxLength: 250, // Character limit added here
        },
        ...InputProps,
      }}
      sx={{
        borderRadius: '.8rem',
        border: `1px solid ${error ? '#ff0000' : '#D9D9D9'}`,
        boxshadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        padding: '.6rem 1.4rem',
        flex: 1,
      }}
    />
    {error && <Typography color="error">{errorMessage}</Typography>}
  </Stack>
)

export default TextInput
