// utils/validators.ts
// This validators functions validates input string to match certain regex expressions

const validateEmail = (email: string) => {
  // eslint-disable-next-line
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/.test(email)) {
    return true
  }
  return false
}

/**
 * @function
 * validates Password string and needs to pass next checks
 * to be at least 8 chars long
 * to have at least one special character
 * to have at least one number
 * to have at least one capital letter
 */
const checkPassword = (str: string) => {
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[£€!@#$%^&*()\-_=+[\]{};:'",.<>/?\\|]).{8,}$/
  return regex.test(str)
}

const atLeast8chars = (str: string) => {
  const regex = /^.{8,}$/
  return regex.test(str)
}

function hasSpecialCharacter(str: string) {
  const regex = /[£€!@#$%^&*()\-_=+[\]{};:'",.<>/?\\|]/
  return regex.test(str)
}

function hasNumber(str: string) {
  const regex = /\d/
  return regex.test(str)
}

function hasUppercaseLetter(str: string) {
  const regex = /[A-Z]/
  return regex.test(str)
}

function hasWhitespace(str: string) {
  const regex = /\s/
  return regex.test(str)
}

const validateCyqurToken = (str: string) => {
  const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
  return regex.test(str)
}

export default {
  validateEmail,
  checkPassword,
  atLeast8chars,
  hasSpecialCharacter,
  hasNumber,
  hasUppercaseLetter,
  hasWhitespace,
  validateCyqurToken,
}
