import { AxiosResponse } from 'axios'
import { IGetProfileResponse, loginUserResponse } from '../redux/user/userTypes'
import { binariiApi } from '../utils/axiosConfig'

const loginUser = (username: string, password: string): Promise<AxiosResponse<loginUserResponse>> =>
  binariiApi.post(`login`, { username, password })

const registerUser = (email: string, password: string): Promise<void> =>
  binariiApi.post(`user/register/`, {
    email,
    password,
  })

const refreshToken = (refresh: string | null): Promise<AxiosResponse<void>> =>
  binariiApi.get(`user/refresh/`, { headers: { Authorization: `Bearer ${refresh}` } })

const getProfile = (): Promise<AxiosResponse<IGetProfileResponse>> => binariiApi.get(`me/`)

const getUserInfo = (): Promise<AxiosResponse<IGetProfileResponse>> => binariiApi.get(`user/info/`)

const setUserInfo = (name: string, jobTitle: string, email: string): Promise<AxiosResponse> =>
  binariiApi.patch(`user/info/`, { name, jobTitle, email })

const updateMember = (
  userId: string,
  startDate: number,
  endDate: number,
  adminUser: boolean
): Promise<AxiosResponse> =>
  binariiApi.put(`user/${userId}`, {
    startDate,
    endDate,
    adminUser,
  })

const resetMemberPassword = (userId: string): Promise<AxiosResponse> =>
  binariiApi.put('user/info/password/reset', { userId })

const changeUserPassword = (currentPassword: string, newPassword: string): Promise<AxiosResponse> =>
  binariiApi.put('user/info/password/change', {
    currentPassword,
    newPassword,
  })

const forgotPassword = (email: string): Promise<AxiosResponse> =>
  binariiApi.post('forgot/password', { email })

export default {
  loginUser,
  registerUser,
  refreshToken,
  getProfile,
  getUserInfo,
  setUserInfo,
  updateMember,
  resetMemberPassword,
  changeUserPassword,
  forgotPassword,
}
