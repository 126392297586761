/* eslint-disable */
import { useContext } from 'react'
import './App.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import RequireAuth from './components/router/RequireAuth'
import LoginAuth from './components/router/LoginAuth'
import { SnackbarProvider } from 'notistack'
import MultiFactorAuthWizzard from './components/organisms/wizzards/MultiFactorAuthWizzard'
import { LayoutThemeContext } from './context/LayoutThemeContext'
import { TLayoutThemeContext } from './context/LayoutThemeContext/types'

const App = () => {
  const { appMuiTheme } = useContext(LayoutThemeContext) as TLayoutThemeContext

  return (
    <MuiThemeProvider theme={appMuiTheme}>
      <SnackbarProvider maxSnack={4} autoHideDuration={4000}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route element={<LoginAuth />}>
              <Route path="/login" element={<MultiFactorAuthWizzard />} />
            </Route>
            <Route path="/*" element={<RequireAuth />}></Route>
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </MuiThemeProvider>
  )
}

export default App
